@use '@angular/material' as mat;
@import '~viewerjs/dist/viewer.css';
@import 'ngx-toastr/toastr';
@import 'markdown';
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';
@import '~@uppy/webcam/dist/style.css';
@import '~@uppy/image-editor/dist/style.css';

* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;

  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  height: 100%;
}

html.translated-ltr {
  min-height: calc(100vh - 40px) !important;
  height: calc(100vh - 40px) !important;
}

.mat-snack-bar-container {
  &.success {
    background: #5cb85c;
  }
}

.viewer-backdrop,
mat-card.transparent {
  background-color: #eee;
  background-image: linear-gradient(45deg, #999 25%, transparent 25%, transparent 75%, #999 75%, #999),
    linear-gradient(45deg, #999 25%, transparent 25%, transparent 75%, #999 75%, #999);
  background-size: 2rem 2rem;
  background-position: 0 0, 1rem 1rem;
}

button,
.button {
  &.mat-raised-button {
    &.mat-accent,
    &.mat-warn,
    &.mat-primary {
      .mat-progress-spinner {
        circle {
          stroke: #ffffff;
        }
      }
    }
  }

  .mat-progress-spinner:not(.fab-spinner) {
    display: inline-block;
    margin-right: 8px;
  }
}

.filter-form {
  padding: 24px 12px;

  .mat-form-field {
    padding: 0 12px;
  }
}

mat-dialog-actions.mat-dialog-actions {
  min-height: 0;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.mat-form-field {
  line-height: 1.145;
}

.filters-result-count {
  float: right;
  font-weight: bold;

  mat-chip {
    font-size: 15px;
  }
}

table.mat-table {
  width: 100%;

  .mat-row {
    transition: background-color 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  }

  tr:nth-child(even) {
    background-color: #fcfcfc;
  }

  tr:hover:not(.team-row) {
    background-color: #f5f5f5;

    &:nth-child(even) {
      background-color: #f5f5f5;
    }
  }

  .mat-sort-header-container {
    justify-content: center;
  }

  td .mat-icon {
    vertical-align: middle;
  }

  td.mat-cell,
  th.mat-header-cell {
    text-align: center;
  }

  td.ellipsis-wrapper {
    position: relative;
    max-width: 100px;
    width: 140px;
    text-align: left;
  }

  .ellipsis-text {
    max-width: 100px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    font-size: 12px;
    white-space: nowrap;
  }

  .view-ellipsis-text {
    position: absolute;
    top: 3px;
    right: 0;
  }

  tr.special-treatment,
  tr.special-treatment:nth-child(even) {
    background-color: #f9eee6;

    &:hover {
      background-color: darken(#f9eee6, 5%);
    }
  }

  tr.expedited,
  tr.expedited:nth-child(even) {
    background-color: #fbd2d2;

    &:hover {
      background-color: darken(#fbd2d2, 5%);
    }
  }

  tr.predesigned,
  tr.predesigned:nth-child(even) {
    background-color: #f8d3ed;

    &:hover {
      background-color: darken(#f8d3ed, 5%);
    }
  }

  tr.reverted,
  tr.reverted:nth-child(even) {
    background-color: #fbd5c4;

    &:hover {
      background-color: darken(#fbd5c4, 5%);
    }
  }

  tr.cooldown,
  tr.cooldown:nth-child(even) {
    background-color: map-get($accent, 50);

    &:hover {
      background-color: darken(map-get($accent, 50), 5%);
    }
  }

  tr.re-shipment,
  tr.re-shipment:nth-child(even) {
    background-color: #ff4149;

    td {
      color: #ffffff;
    }

    &:hover {
      background-color: darken(#ff4149, 5%);
    }
  }

  tr.re-order,
  tr.re-order:nth-child(even) {
    background-color: #c7d083;

    td {
      color: #ffffff;
    }

    &:hover {
      background-color: darken(#c7d083, 5%);
    }
  }

  tr.imported,
  tr.imported:nth-child(even) {
    background-color: #ab9ba9;

    td {
      color: #ffffff;
    }

    &:hover {
      background-color: darken(#ab9ba9, 5%);
    }
  }

  tr.shipment-on-hold,
  tr.shipment-on-hold:nth-child(even) {
    background-color: #505050;

    td {
      color: #ffffff;
    }

    &:hover {
      background-color: darken(#505050, 5%);
    }
  }

  tr.deleted,
  tr.deleted:nth-child(even) {
    background-color: #d08383;
    opacity: 0.3;

    &:hover {
      background-color: darken(#d08383, 5%);
    }
  }

  td.mat-footer-cell {
    text-align: center;
  }

  tr.mat-footer-row {
    background: map-get($accent, 50);
    font-weight: bold;

    td {
      color: map-get($accent, A700);
    }
  }
}

.mat-chip.mat-standard-chip {
  &.is-manager {
    background-color: map-get($accent, 500);
    color: #ffffff;
  }
}

.table-legend {
  float: right;
  font-size: 12px;

  &.table-legend-only {
    margin-top: -7px;
  }

  .legend {
    margin-left: 1rem;

    &:before {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      content: '';
      margin-right: 3px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }

    &.legend-special-treatment:before {
      background-color: #f9eee6;
    }

    &.legend-expedited:before {
      background-color: #fbd2d2;
    }

    &.legend-predesigned:before {
      background-color: #f8d3ed;
    }

    &.legend-reverted:before {
      background-color: #fbd5c4;
    }

    &.legend-cooldown:before {
      background-color: map-get($accent, 50);
    }

    &.legend-is-manager:before {
      background-color: map-get($accent, 500);
    }

    &.legend-is-member:before {
      background-color: #e0e0e0;
    }

    &.legend-re-shipment:before {
      background-color: #ff4149;
    }

    &.legend-re-order:before {
      background-color: #c7d083;
    }

    &.legend-imported:before {
      background-color: #ab9ba9;
    }

    &.legend-shipment-on-hold:before {
      background-color: #505050;
    }
  }
}

.table-empty-notice,
.info-notice {
  border-bottom: 1px solid #cccccc;

  mat-icon {
    vertical-align: middle;
    position: relative;
    top: -3px;
    color: #333333;
  }
}

.section {
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }

  &.section-limit {
    max-width: 1400px;
    margin: 0 auto 48px;
  }
}

.stamp {
  position: absolute;
  top: 20%;
  left: 45%;
  z-index: 1;
  font-family: Arial, sans-serif;
  transform: rotate(-35deg) translate(-45%, -20%);
  font-size: 6vw;
  color: #cc0000;
  background: #ffffff;
  border: solid 4px #cc0000;
  padding: 5px;
  border-radius: 5px;
  zoom: 1;
  opacity: 0.2;
  text-shadow: 0 0 2px #cc0000;
  box-shadow: 0 0 2px #cc0000;
  user-select: none;
  text-transform: uppercase;
  pointer-events: none;

  &.stamp-animated {
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-name: stamp;
  }

  &.stamp-refunded {
    color: orange;
    border-color: orange;
    text-shadow: 0 0 2px orange;
    box-shadow: 0 0 2px orange;
    opacity: 0.5;
  }

  &.stamp-success {
    color: green;
    border-color: green;
    text-shadow: 0 0 2px green;
    box-shadow: 0 0 2px green;
  }
}

@keyframes stamp {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
    transform-origin: 50% 50%;
    transform: rotate(-20deg) scale(2) translate(-22%, -10%);
    transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  100% {
    opacity: 0.2;
    transform: rotate(-35deg) scale(1) translate(-45%, -20%);
  }
}

#toast-container {
  p {
    margin: 0;
  }

  &.toast-top-right {
    top: 82px;
  }
}

div[ngxViewer] img,
img[ngxViewer] {
  cursor: pointer;
}

mat-card {
  .icon-title {
    display: flex;
    align-items: center;

    mat-icon {
      margin-right: 0.5rem;
    }
  }
}

.progress-message-feed {
  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    padding: 0.5rem;
    border-left: 6px solid;
    margin: 0.5rem 0;

    &.success {
      border-color: #5cb85c;
    }

    &.info {
      border-color: map-get($accent, 500);
    }

    &.error {
      border-color: #ff4149;
    }

    span {
      margin-left: 0.5rem;
    }
  }
}

.is-navigating.mat-sidenav-content {
  overflow-y: hidden;
}

.global-spinner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  &.navigation {
    width: auto;
    position: sticky;
    background: #ffffff;
    z-index: 100000;
  }
}

.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: map-get($accent, 500);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.mat-list-item.is-active {
  background: map-get($accent, 50);
  border-left: 5px solid map-get($accent, A700);
}

::ng-deep .payment-row,
::ng-deep .tracking-item {
  a:link,
  a:visited {
    color: mat.get-color-from-palette($accent);
    font-weight: bold;
  }
}

.environment-indicator {
  color: map-get($accent, 500);
}

.file-wrapper {
  a {
    color: map-get($accent, 500);
  }
}

.comment-wrapper.own {
  .comment-meta {
    background-color: map-get($accent, 500);
  }
}

.uppy-StatusBar,
.uppy-DashboardContent-bar {
  z-index: 4;
}

.spinner-absolute-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

table.print-queue {
  font-size: 12px;

  td,
  th {
    padding: 0.25rem 0.75rem;
  }

  th {
    text-align: left;
  }

  td {
    text-align: center;
  }
}
